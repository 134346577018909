import React from "react"
import Section1 from "../Section1"
import Section3 from "../Section3"

export const PitPayHome = () => {
  return (
    <>
      <Section1 />
      <Section3 />
    </>
  )
}
