import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TrackPage from "../components/trackHome/TrackPage"
import SmartBanner from "react-smartbanner"
import "../../node_modules/react-smartbanner/dist/main.css"
import { PitPayHome } from "../components/pitpayHome/PitPayHome"

const IndexPage = () => {
  const isTickethoss = process.env.GATSBY_APP_PLATFORM === "tickethoss"

  return (
    <Layout maxHeight={true}>
      <SmartBanner title={isTickethoss ? "Ticket Hoss" : "Pit Pay"} />
      <SEO title={isTickethoss ? "Ticket Hoss" : "Pit Pay"} />

      {isTickethoss ? <TrackPage /> : <PitPayHome />}
    </Layout>
  )
}

export default IndexPage
